<!-- 媒体中心 -->
<template>
  <div class="mediaCenter">
    <img class="banner" src="@/assets/images/media/banner.jpg" alt="">
    <v-tabs class="w1200">
      <v-tab @click="changeActiveTab(1)">配友资讯</v-tab>
      <v-tab @click="changeActiveTab(2)">行业新闻</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <div class="list w1200">
      <template v-if="activeTab === 1">
        <div class="list-item" v-for="(item, index) in listData1" :key="index" @click="goDetail(item)">
          <div class="imgDiv">
            <img :src="item.thumb" alt="" />
          </div>
          <h6>{{ item.title }}</h6>
          <p class="desc">{{ item.desc }}</p>
          <span>{{ item.release_time }}</span>
        </div>
        <div class="empty" v-if="!listData1.length && !loading1">
          <img src="@/assets/images/media/empty.jpg" alt="">
          <span>暂无配友资讯</span>
        </div>
      </template>

      <template v-if="activeTab === 2">
        <div class="list-item" v-for="(item, index) in listData2" :key="index" @click="goDetail(item)">
          <div class="imgDiv">
            <img :src="item.thumb" alt="" />
          </div>
          <h6>{{ item.title }}</h6>
          <p class="desc">{{ item.desc }}</p>
          <span>{{ item.release_time }}</span>
        </div>
        <div class="empty" v-if="!listData2.length && !loading2">
          <img src="@/assets/images/media/empty.jpg" alt="">
          <span>暂无行业新闻</span>
        </div>
      </template>

    </div>
    <div class="pagination-p w1200 clearfix">
      <template v-if="activeTab === 1 && total1">
        <v-pagination class="pagination" v-model="page1" :length="total1" :total-visible="7"></v-pagination>
      </template>
      <template v-if="activeTab === 2 && total2">
        <v-pagination class="pagination" v-model="page2" :length="total2" :total-visible="7"></v-pagination>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: "媒体中心-配友软件",
      // meta: [
      //   { charset: "utf-8" },
      //   { name: "description", content: "配友媒体中心汇集最新配友资讯与汽配行业新闻，为汽配业者提供决策参考。多年来，配友媒体中心力求做好连接生产、销售及媒体的纽带角色，助推汽配产业发展" },
      // ],
    };
  },
  data() {
    return {
      activeTab: 1,

      listData1: [], // 配友资讯
      total1: 0,
      page1: 1,
      loading1: false,

      listData2: [], // 行业新闻
      total2: 0,
      page2: 1,
      loading2: false,
    }
  },
  created() {
    document.querySelector('meta[name="description"]').setAttribute('content', "配友媒体中心汇集最新配友资讯与汽配行业新闻，为汽配业者提供决策参考。多年来，配友媒体中心力求做好连接生产、销售及媒体的纽带角色，助推汽配产业发展");
  },
  mounted() {
    this.getList(1, 1);
    this.getList(2, 1);
  },
  watch: {
    page1(newVal) {
      this.getList(1, newVal);
    },
    page2(newVal) {
      this.getList(2, newVal);
    },
  },
  methods: {
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
    getList(type, page) {
      if (type === 1) {
        this.loading1 = true;
      }
      if (type === 2) {
        this.loading2 = true;
      }
      this.$http({
        url: this.$store.state.requestUrl + "/v1.1/information",
        method: 'get',
        params: {
          category: type, // 1配友资讯 2行业资讯
          page: page,
          pagesize: 9,
        }
      }).then(res => {
        if (res.data.code === 0) {
          if (type === 1) {
            this.listData1 = res.data.data.data;
            this.total1 = Math.ceil(res.data.data.total / 9);
            this.loading1 = false;
          } else if (type === 2) {
            this.listData2 = res.data.data.data;
            this.total2 = Math.ceil(res.data.data.total / 9);
            this.loading2 = false;
          }
        }
      }).catch(err => {
        console.log(err);
      })
    },
    goDetail(detail) {
      if (detail.type == 1) {
        this.$router.push("mediaDetail.html?id=" + detail._id)
      } else if (detail.type == 2) {
        window.open(detail.url)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.mediaCenter {

  :deep .v-tabs {
    .v-tabs-bar {
      height: 60px;
    }

    .v-tab {
      padding: 0;
      color: #333 !important;
      font-size: 20px;
      margin-right: 60px;
    }

    .v-tabs-slider-wrapper {
      height: 4px;
      border-radius: 1px;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 64px;
  }

  .list-item {
    width: 382px;
    height: 450px;
    margin-right: 20px;
    margin-bottom: 48px;
    cursor: pointer;
    position: relative;

    &.v-sheet.v-card:not(.v-sheet--outlined) {
      box-shadow: none;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    div.imgDiv {
      width: 100%;
      height: 241px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h6 {
      font-weight: normal;
      font-family: PingFang-SC-Bold;
      font-size: 20px;
      line-height: 30px;
      color: #333333;
      padding: 0 10px;
      margin: 20px 0 10px;
      overflow: hidden; //超出文本隐藏
      text-overflow: ellipsis; ///超出部分省略号显示
      display: -webkit-box; //弹性盒模型
      -webkit-box-orient: vertical; //上下垂直
      -webkit-line-clamp: 2; //自定义行数
    }

    &:hover {
      transform: translateY(-4px);
      transition: ease all 0.3s;
      box-shadow: 0px 5px 5px 0px rgba(230, 230, 230, 0.75);

      h6 {
        color: #1976d2;
      }

      img {
        transition: transform .5s ease-out;
        -o-transition: transform .5s ease-out;
        -webkit-transition: transform .5s ease-out;
        -moz-transition: transform .5s ease-out;
        transform: scale(1.1);
        -ms-transform: scale(1.1);
        -webkit-transform: scale(1.1);
      }
    }

    p {
      font-family: PingFang-SC-Medium;
      font-size: 16px;
      line-height: 30px;
      color: #333333;
      margin-bottom: 0;
      padding: 0 10px;
      width: 100%;
      overflow: hidden; //超出文本隐藏
      text-overflow: ellipsis; ///超出部分省略号显示
      display: -webkit-box; //弹性盒模型
      -webkit-box-orient: vertical; //上下垂直
      -webkit-line-clamp: 2; //自定义行数
    }

    span {
      position: absolute;
      bottom: 20px;
      left: 10px;
      font-size: 16px;
      color: #999999;
    }
  }

  .pagination-p {
    margin: 0 auto 50px;

    .pagination {
      float: right;
    }
  }

  .empty {
    width: 100%;
    text-align: center;

    img {
      display: block;
      width: 332px;
      height: 340px;
      margin: 0 auto 38px;
    }

    span {
      font-size: 24px;
      color: #888888;
    }
  }
}
</style>